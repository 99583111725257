<template>
  <v-container>
    <!-- <div class="text-center py-5">
      <h4 class="text-h4 font-weight-regular">User Resources</h4>
    </div> -->

    <v-data-table
      id="resource-table"
      class="elevation-2"
      item-key="id"
      disable-sort
      :loading="loading"
      :headers="headers"
      :items="resources"
      :page.sync="page"
      :items-per-page.sync="limit"
      :server-items-length="totalItems"
      style="cursor: pointer"
      @click:row="openResourceDetails"
    >
      <template v-slot:top>
        <v-sheet color="lighten-3">
          <div class="d-flex flex-md-row flex-column-reverse align-center px-5 py-2">
            <v-btn color="primary" class="ma-1" @click="addResource" v-if="canCreate">
              <v-icon>mdi-plus</v-icon>
              <div class="reload-text">Add New</div>
            </v-btn>
            <v-spacer />
            <v-text-field
              v-model="query"
              solo
              hide-details
              clearable
              name="filter"
              autocomplete="filter"
              placeholder="Search"
              prepend-inner-icon="mdi-magnify"
              class="search-field ma-0"
            />
          </div>
          <v-divider />
        </v-sheet>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <v-icon v-if="item.locked">mdi-lock</v-icon>
        <span style="font-weight: 500"> {{ item.name }} </span>
      </template>
      <template v-slot:[`item.roles`]="{ item }">
        <v-chip v-for="role in item.roles" :key="role" class="ma-1">{{ role }}</v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex flex-row">
          <v-btn
            fab
            outlined
            small
            color="blue"
            @click.stop="() => changeName(item)"
            v-if="canRename && item.name !== 'Public'"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-spacer class="mx-1" />
          <v-btn
            fab
            outlined
            small
            color="red"
            @click.stop="() => deleteResource(item)"
            v-if="canDelete"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 lighten-3">
          <span> {{ resourceId ? "Edit" : "Add" }} Resrouce </span>
          <v-spacer />
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <form @submit.prevent.stop="submitResource">
          <v-card-text>
            <v-text-field
              v-model="resourceName"
              outlined
              hide-details
              label="Resource Name"
              autocomplete="off"
              @keypress.enter="submitResource"
              required
            />
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="px-10 ma-1" color="primary" :loading="loading" type="submit">
              Submit
            </v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import debounce from "lodash.debounce";
import * as api from "@/constants/api";

export default {
  name: "ResourceList",
  metaInfo: { title: "User Resources" },
  components: {},
  data: () => ({
    loading: false,
    resources: [],
    totalItems: 0,
    dialog: false,
    resourceName: null,
    resourceId: null,
  }),
  computed: {
    headers: () => [
      { text: "Resource Name", value: "name" },
      { text: "Permissions", value: "roles" },
      { text: "Actions", value: "actions", width: 100 },
    ],
    page: {
      get() {
        return Number(this.$route.query.page) || 1;
      },
      set(page) {
        this.pushCurrentRoute({ page });
      },
    },
    limit: {
      get() {
        return Number(this.$route.query.limit) || 15;
      },
      set(limit) {
        this.pushCurrentRoute({ limit, page: 1 });
      },
    },
    query: {
      get() {
        return this.$route.query.query;
      },
      set(query) {
        this.pushCurrentRoute({ query, page: 1 });
      },
    },
    canCreate() {
      return this.verifyPrivilege(api.USER_RESOURCE_CREATE);
    },
    canRename() {
      return this.verifyPrivilege(api.USER_RESOURCE_RENAME);
    },
    canDelete() {
      return this.verifyPrivilege(api.USER_RESOURCE_DELETE);
    },
  },
  watch: {
    $route: {
      deep: true,
      handler({ name }) {
        if (name === "resource.index") {
          this.fetchData();
        }
      },
    },
  },
  created() {
    this.pushCurrentRoute = debounce(this.pushCurrentRoute, 300, { leading: true });
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async pushCurrentRoute({ page, limit, query }) {
      await this.$nextTick();

      const q = {};
      q.query = query === undefined ? this.query : query;
      q.page = page === undefined ? this.page : page;
      q.limit = limit === undefined ? this.limit : limit;

      Object.keys(q).forEach((el) => {
        if (!q[el]) delete q[el];
      });
      this.$router.push({ name: this.$route.name, query: q }).catch(() => {});
    },
    async fetchData() {
      try {
        this.loading = true;
        const params = {
          page: this.page - 1,
          limit: this.limit,
          query: this.query,
        };
        const resp = await this.$axios.get(api.USER_RESOURCE_LIST, { params });
        resp.data.data.items.sort((a, b) => a.id - b.id);
        this.resources = resp.data.data.items;
        this.totalItems = resp.data.data.totalItems;
      } catch (err) {
        this.$iziToast.showError(err);
      } finally {
        this.loading = false;
      }
    },
    openResourceDetails(item) {
      this.$router.push({
        name: "resource.edit",
        params: { id: item.id },
      });
    },
    changeName(item) {
      this.resourceId = item.id;
      this.resourceName = item.name;
      this.dialog = true;
    },
    addResource() {
      this.resourceId = null;
      this.resourceName = null;
      this.dialog = true;
    },
    async submitResource() {
      try {
        this.loading = true;
        if (!this.resourceName) {
          throw new Error("Resource name should not be empty");
        }
        if (this.resourceId) {
          await this.$axios.post(api.USER_RESOURCE_RENAME, null, {
            params: {
              name: this.resourceName,
              resourceId: this.resourceId,
            },
          });
        } else {
          await this.$axios.post(api.USER_RESOURCE_CREATE, null, {
            params: {
              name: this.resourceName,
            },
          });
        }
        this.dialog = false;
        this.fetchData();
      } catch (err) {
        this.$iziToast.showError(err);
      } finally {
        this.loading = false;
      }
    },
    async deleteResource(resource) {
      try {
        const confirm = await this.$iziToast.askConfirmation();
        if (!confirm) return;
        await this.$axios.delete(api.USER_RESOURCE_DELETE, {
          params: {
            resourceId: resource.id,
          },
        });
        this.fetchData();
      } catch (err) {
        this.$iziToast.showError(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped></style>
